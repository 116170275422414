/*
 Color Pallete
*/

$charcoal-light: #404552;
$charcoal-dark: #383c4a;
$independence: #4b5162;
$united-nations-blue: #5294e2;
$roman-silver: #7c818c;
$blanced-almond: #ffead0;
$air-superiority-blue: #759eb8;
$cosmic-latte: #7c818c;
$aero-blue: #7c818c;
$eerie-black: #141414;
$dark-sea-green: #9fc490;
$off-white: #faf9f6;

$mercury: #1a1a1a;
$venus: #e6e6e6;
$earth: #2f6a69;
$mars: #993d00;
$jupiter: #b07f35;
$saturn: #b08f36;
$uranus: #5580aa;
$neptune: #366896;

:root {
	--charcoal-light: #{$charcoal-light};
	--charcoal-dark: #{$charcoal-dark};
	--independence: #{$independence};
	--united-nations-blue: #{$united-nations-blue};
	--roman-silver: #{$roman-silver};
	--blanced-almond: #{$blanced-almond};
	--air-superiority-blue: #{$air-superiority-blue};
	--cosmic-latte: #{$cosmic-latte};
	--aero-blue: #{$aero-blue};
	--eerie-black: #{$eerie-black};
	--dark-sea-green: #{$dark-sea-green};
	--off-white: #{$off-white};
	--mercury: #{$mercury};
	--venus: #{$venus};
	--earth: #{$earth};
	--mars: #{$mars};
	--jupiter: #{$jupiter};
	--saturn: #{$saturn};
	--uranus: #{$uranus};
	--neptune: #{$neptune};
}

/**
 End Color Pallete
*/

/* Body */

body .content {
	scroll-snap-points-y: repeat(300px);
	// scroll-snap-type: y proximity;
	// scroll-snap-type: proximity;
}

/* Chrome only css hack, to fix scrollbar overlay. */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	body .content {
		overflow: overlay;
	}
}

.shadow-bottom {
	box-shadow: 0px 20px 10px -20px rgba(0, 0, 0, 0.45) inset;
}

/* Works on Chrome, Edge, and Safari */
/* Firefox looks good. */

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 8px 8px transparent;
	border: solid 2px transparent;
}

*::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 8px 8px #858585;
	border: solid 2px transparent;
	border-radius: 4pt;
}

/* End Body */

#control-panel svg,
#links svg {
	color: $roman-silver;
}

#control-panel button:hover svg,
#control-panel button:hover span,
#links button:hover svg {
	color: white;
}

#greeting {
	position: absolute;
	color: white;
	font-family: 'Courier New', Courier, monospace;
}

.mobile #greeting {
	top: 5%;
	left: 5%;
}

#greeting-links,
.cursor-small {
	font-size: 3vmin;
}

.mobile #links,
.mobile #control-panel {
	font-size: 6vmin;
}
