section.page-not-found {
	color: #888;
	font-family: sans-serif;
	text-align: center;
	width: 100vw;
	display: table-cell;
	vertical-align: middle;
	margin: 2em auto;
}

section.page-not-found h1 {
	color: #555;
	font-size: 2em;
	font-weight: 400;
}

section.page-not-found p {
	margin: 0 auto;
	width: 280px;
}

@media only screen and (max-width: 280px) {
	section.page-not-found p {
		width: 95vw;
	}

	section.page-not-found h1 {
		font-size: 1.5em;
		margin: 0 0 0.3em;
	}
}
